<template>
  <header class="fixed-top">
    <div class="header-wrapper">
      <h1><router-link to="/"><img :src="require('@/assets/img/logo_top.png')" 
        :srcset="`${require('@/assets/img/logo_top.png')} 1x, ${require('@/assets/img/logo_top@2x.png')} 2x`"
        alt="PSALMS 150"/></router-link></h1>
      
      <ul class="nav justify-content-end" id="navbar-pc">
        <li class="nav-item" @click="goMenu('main-visual', false)">
          {{ $t("header.menu1")}}
        </li>
        <li class="nav-item" @click="goMenu('request', false)">
          {{ $t("header.menu2")}}
        </li>
        <li class="nav-item" @click="goMenu('music', false)">
          {{ $t("header.menu3")}}
        </li>
        <li class="nav-item" @click="goMenu('notice', false)">
          {{ $t("header.menu4")}}
        </li>
        <li class="nav-item">
          <a @click="$i18n.locale='ko'" :class="$i18n.locale=='ko' ? 'strong': ''"> KOR </a> /
          <a @click="$i18n.locale='en'" :class="$i18n.locale=='en' ? 'strong': ''"> ENG </a>
        </li>
      </ul>
      <b-collapse id="navbar-collapse">
        <ul class="nav">
          <li class="nav-item" @click="goMenu('main-visual', true)">
            {{ $t("header.menu1")}}
          </li>
          <li class="nav-item" @click="goMenu('request', true)">
            {{ $t("header.menu2")}}
          </li>
          <li class="nav-item" @click="goMenu('music', true)">
            {{ $t("header.menu3")}}
          </li>
          <li class="nav-item" @click="goMenu('notice', true)">
            {{ $t("header.menu4")}}
          </li>
          <li class="nav-item">
            <a @click="$i18n.locale='ko'; $root.$emit('bv::toggle::collapse', 'navbar-collapse')" :class="$i18n.locale=='ko' ? 'strong': ''"> KOR </a> /
            <a @click="$i18n.locale='en'; $root.$emit('bv::toggle::collapse', 'navbar-collapse')" :class="$i18n.locale=='en' ? 'strong': ''"> ENG </a>
          </li>
      </ul>
      </b-collapse>
      <button type="button" class="navbar-toggle collapsed" v-b-toggle.navbar-collapse>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'CommonHeader',
  computed: {
  },
  watch: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    goMenu(menu_cd, close_yn) {
      if (menu_cd == 'main-visual') {
        this.$router.push('/')
      } else if (menu_cd == 'request') {
        if (process.env.VUE_APP_REQUEST_YN == 'Y') {
          this.$router.push('/request')
        } else if (process.env.VUE_APP_REQUEST_YN == 'N') {
          alert(this.$t('request.no-request'))
        }
      } else if (menu_cd == 'music') {
        // alert('준비중입니다.')
        this.$router.push('/music')
      } else if (menu_cd == 'notice') {
        // alert('준비중입니다.')
        this.$router.push('/notice')
      }
      if (close_yn) {
        this.$root.$emit('bv::toggle::collapse', 'navbar-collapse')
      }
    }
  }
}
</script>

<style scoped>
  header {width: 100%; background-color: white; border-bottom: 1px solid #C7C7C7;}
  header .header-wrapper {height: 104px; width: 1080px; max-width: 100%; height:104px; margin: 0 auto; display: flex; justify-content: space-between; align-items: center;}
  header h1 img {display: block;}
  header .nav li {padding: 8px 16px; cursor: pointer; font-size: 0.9rem; letter-spacing: -0.72px;}
  header .navbar-toggle {display: none;}
  #navbar-collapse {display: none;}
  #navbar-pc {display: flex;}
  header .nav .nav-item:hover, .nav-item a:hover {color: #40247D;}
  .nav-item .strong {font-weight: bold;}
  .nav-item a {color: #242424; text-decoration: none;}

  @media (max-width: 640px) {
    /* 사용자 해상도가 640px 이하일 때 */
    header .nav.collapse {display:flex!important;}
    header .navbar-toggle {position: absolute; top:25px; right:0; display: block; background: 0 0; margin: 0 0 0 -15px; padding: 18px; border: 1px solid transparent; border-radius: 0; outline: 0;}
    .navbar-toggle .icon-bar {display: block; width: 15px; height: 2px; border-radius: 1px; background: #777;}
    .navbar-toggle .icon-bar+.icon-bar {margin-top: 4px;}
    #navbar-pc {display: none;}
    #navbar-collapse {display: block; position: absolute; top: 104px; right: 0; z-index: 1;}
    header .nav {background-color: white; border: 1px solid #DBDBDB; width:180px; margin-left:calc(100% - 180px); padding: 10px 0;}
    header .nav .nav-item {width: 100%;}
  }
  
  @media (max-width: 640px) and (min-width: 481px) {
    /* 사용자 해상도가 481px 부터 640px일 때 */
    header h1 img {max-width: 200px;}
  }

  @media (max-width: 480px) {
    /* 사용자 해상도가 480px 이하일 때 */
    header h1 img {max-width: 160px;}
  }
</style>
