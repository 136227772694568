<template>
  <div id="app">
    <Header />
    <div class="content-wrapper">
      <router-view/>
    </div>
    <Footer />
    <div :class="[popup_yn == 'N' ? 'hidden' : '', 'popup_bg']">
      <div class="popup">
        <img :src="require('@/assets/img/popup/popup240710.jpg')" />
        <div class="d-flex justify-content-between">
          <p @click="closePopup('N')">더 이상 보지 않기</p>
          <p @click="closePopup('Y')">&times;</p>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
// import { getCookie, setCookie } from '@/assets/js/common.js'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data: () => ({
    popup_yn: 'N'
  }),
  mounted () {
    /*
    const now = new Date()
    const popup_time = new Date('2024-07-10 09:00:00')
    if (now >= popup_time) {
      this.popup_yn = getCookie('popup240710')
    } else {
      this.popup_yn = 'N'
    }
    */
  },
  methods: {
    /*
    closePopup(nextViewYN) {
      this.popup_yn = 'N'
      if (nextViewYN == 'N') {
        setCookie('popup240710', 'N', -1)
      }
    }*/
  }
}
</script>

<style scoped>
  .popup_bg {position: fixed; z-index: 1040; top:0; left:0; background-color: rgba(0, 0, 0, 0.7); width: 100vw; height: 100vh;}
  .popup_bg.hidden {display: none;}
  .popup {position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);}
  .popup img {max-width: calc(100vw - 100px); max-height: calc(100vh - 100px);}
  .popup p {text-align: right; color: white; cursor: pointer;}
  .content-wrapper {padding-top: 104px;}
  
  @media (max-width: 1920px) and (min-width: 600px) {
    .popup img {max-width: 521px; max-height: 521px;}
  }
</style>