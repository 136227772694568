import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

// 하위 모듈 import
import defaultStore from '@/store/module/defaultStore'
// import authStore from '@/store/module/authStore'
// import memberStore from '@/store/module/memberStore'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    // paths: ['authStore', 'memberStore'],

  })],
	modules: {
    defaultStore,
		// authStore,
		// memberStore,
	},
})