<template>
  <div id="home-main-wrapper">
    <div class="main-info">
      <div id="main-visual" ref="main-visual" class="content-box main-visual" v-if="$i18n.locale=='ko'">
        <img
        class="title-text" :src="require('@/assets/img/2407a/text01.png')" 
        :srcset="`${require('@/assets/img/2407a/text01.png')} 1x, ${require('@/assets/img/2407a/text01@2x.png')} 2x`"
        alt="시편을 노래하다 PSALMS 150 PROJECT" />
      </div>
      <div class="content-box main-visual-old" v-else>
        <img
        class="title-text" :src="require('@/assets/img/en/text01.png')" 
        :srcset="`${require('@/assets/img/en/text01.png')} 1x, ${require('@/assets/img/en/text01@2x.png')} 2x`"
        alt="Sing the Psalms! PSALMS 150 PROJECT" />
      </div>
      <div class="content-box guide" v-if="$i18n.locale=='ko'">
        <p class="headline"><img
        :src="require('@/assets/img/2407a/text_main_about.png')" 
        :srcset="`${require('@/assets/img/2407a/text_main_about.png')} 1x, ${require('@/assets/img/2407a/text_main_about@2x.png')} 2x`"
        alt="ABOUT PSALMS 150 PROJECT" /></p>
        <img class="text-img" src="require('@/assets/img/2407a/text01-1.png')" 
        :srcset="`${require('@/assets/img/2407a/text01-1.png')} 1x, ${require('@/assets/img/2407a/text01-1@2x.png')} 2x`"
        alt="ABOUT PSALMS 150 PROJECT" />
      </div>
      <div class="content-box guide-old" v-else>
        <p class="headline" v-html="$t('home.main-info.headline')"></p>
        <img src="require('@/assets/img/text_main_about.png')" 
        :srcset="`${require('@/assets/img/text_main_about.png')} 1x, ${require('@/assets/img/text_main_about@2x.png')} 2x`"
        alt="ABOUT PSALMS 150 PROJECT" />
        <p v-html="$t('home.main-info.guide1')"></p>
        <p v-html="$t('home.main-info.guide2')"></p>
        <p v-html="$t('home.main-info.guide3')"></p>
      </div>
    </div>
    <div class="support-info" v-if="$i18n.locale=='ko'">
      <div class="content-box guide">
        <p class="headline"><strong>시편 150 프로젝트와 함께해 주세요!</strong></p>
        <p><strong>‘시편 150 프로젝트’</strong>는 하나님께 시편 150 편 모두를 새 찬양으로 만들어 올려드리고 아마추어 뮤지션과 프로 뮤지션들이 협력하고 또 세대와 세대가 연결되어 함께 하며 한국과 세계 열방 곳곳마다 하나님의 나라가 임하도록 하는 12 년 기간의 긴 호흡의 프로젝트입니다.</p>
        <p>복음의 전함에서는 <strong>‘시편 150 프로젝트’</strong>를 ‘들어볼까’플랫폼의 ‘만나다, 바뀌다, 더알다, 배우다, 정하다’에 이어 ‘부르다’로 확장하는 계획으로 실행하고 있습니다.</p>
        <p>후원해주시는 재정은 <strong>‘시편 150 프로젝트’</strong>의 음원과 영상제작 그리고 홍보 등에 사용됩니다. 많은 관심과 기도와 후원을 부탁드립니다.</p>
        <p class="account"><strong>후원계좌</strong>/ 국민은행 059401-04-289565 (시편150프로젝트)</p>
      </div>
    </div>
    <div class="competition-info">
      <div class="content-box">
        <div class="guide-sub1">
          <div class="title-text">
            <img v-if="$i18n.locale=='ko'"
            :src="require('@/assets/img/text02.png')" 
            :srcset="`${require('@/assets/img/text02.png')} 1x, ${require('@/assets/img/text02@2x.png')} 2x`"
            alt="시편을 노래하다 PSALMS 150 공모제" />
            <img v-else
            :src="require('@/assets/img/en/text02.png')" 
            :srcset="`${require('@/assets/img/en/text02.png')} 1x, ${require('@/assets/img/en/text02@2x.png')} 2x`"
            alt="Sing the Psalms! PSALMS 150 Contest" />
          </div>
          <h3><img v-if="$i18n.locale=='ko'" 
            :src="require('@/assets/img/title02-01.png')" 
            :srcset="`${require('@/assets/img/title02-01.png')} 1x, ${require('@/assets/img/title02-01@2x.png')} 2x`"
            alt="공모내용"/><img v-else
            :src="require('@/assets/img/en/title02-01.png')" 
            :srcset="`${require('@/assets/img/en/title02-01.png')} 1x, ${require('@/assets/img/en/title02-01@2x.png')} 2x`"
            alt="Contest Description"
          /></h3>
          <ul class="no-indent">
            <li v-html="$t('home.competition-info.competition01.guide1')"></li>
            <li v-html="$t('home.competition-info.competition01.guide2')"></li>
            <li v-html="$t('home.competition-info.competition01.guide3')"></li>
            <li v-html="$t('home.competition-info.competition01.guide4')"></li>
            <li v-html="$t('home.competition-info.competition01.guide5')"></li>
          </ul>
          <h3><img v-if="$i18n.locale=='ko'" 
            :src="require('@/assets/img/title02-04.png')" 
            :srcset="`${require('@/assets/img/title02-04.png')} 1x, ${require('@/assets/img/title02-04@2x.png')} 2x`"
            alt="공모일정"/><img v-else 
            :src="require('@/assets/img/en/title02-04.png')" 
            :srcset="`${require('@/assets/img/en/title02-04.png')} 1x, ${require('@/assets/img/en/title02-04@2x.png')} 2x`"
            alt="Contest Date"/></h3>
          <ul class="no-indent">
            <li v-html="$t('home.competition-info.competition02.guide1')"></li>
          </ul>
          <h3><img v-if="$i18n.locale=='ko'"
            :src="require('@/assets/img/title02-05.png')" 
            :srcset="`${require('@/assets/img/title02-05.png')} 1x, ${require('@/assets/img/title02-05@2x.png')} 2x`"
            alt="시상내역"/><img v-else
            :src="require('@/assets/img/en/title02-05.png')" 
            :srcset="`${require('@/assets/img/en/title02-05.png')} 1x, ${require('@/assets/img/en/title02-05@2x.png')} 2x`"
            alt="Prize"/></h3>
          <ul class="no-indent">
            <li v-html="$t('home.competition-info.competition03.guide1')"></li>
          </ul>
          <h3><img v-if="$i18n.locale=='ko'"
            :src="require('@/assets/img/title02-06.png')" 
            :srcset="`${require('@/assets/img/title02-06.png')} 1x, ${require('@/assets/img/title02-06@2x.png')} 2x`"
            alt="당선곡 발표"/><img v-else
            :src="require('@/assets/img/en/title02-06.png')" 
            :srcset="`${require('@/assets/img/en/title02-06.png')} 1x, ${require('@/assets/img/en/title02-06@2x.png')} 2x`"
            alt="Announcement of the Winner"/></h3>
          <ul class="no-indent">
            <li v-html="$t('home.competition-info.competition04.guide1')"></li>
            <li v-html="$t('home.competition-info.competition04.guide2')"></li>
            <li v-html="$t('home.competition-info.competition04.guide3')"></li>
          </ul>
        </div>
        <ul class="guide-sub2 no-indent">
          <li v-html="$t('home.competition-info.guide-sub.guide1')"></li>
          <li v-html="$t('home.competition-info.guide-sub.guide2')"></li>
          <li v-html="$t('home.competition-info.guide-sub.guide3')"></li>
          <li v-html="$t('home.competition-info.guide-sub.guide4')"></li>
          <li v-html="$t('home.competition-info.guide-sub.guide5')"></li>
          <li v-html="$t('home.competition-info.guide-sub.guide6')" v-if="$i18n.locale=='ko'"></li>
        </ul>
        <div class="logo">
          <img v-if="$i18n.locale=='ko'"
          :src="require('@/assets/img/logo_psalms_shallwelisten.png')"
          :srcset="`${require('@/assets/img/logo_psalms_shallwelisten.png')} 1x, ${require('@/assets/img/logo_psalms_shallwelisten@2x.png')} 2x`"
          alt="시편 150 프로젝트 X 들어볼까" />
          <img v-else
          :src="require('@/assets/img/en/logo_psalms_shallwelisten.png')"
          :srcset="`${require('@/assets/img/en/logo_psalms_shallwelisten.png')} 1x, ${require('@/assets/img/en/logo_psalms_shallwelisten@2x.png')} 2x`"
          alt="PSALMS 150 X 들어볼까" />
        </div>
        <div class="btn" @click="goRequest">
          <img v-if="$i18n.locale=='ko'"
          :src="require('@/assets/img/btn_competition.png')"
          :srcset="`${require('@/assets/img/btn_competition.png')} 1x, ${require('@/assets/img/btn_competition@2x.png')} 2x`"
          alt="공모 참가하기" />
          <img v-else
          :src="require('@/assets/img/en/btn_competition.png')"
          :srcset="`${require('@/assets/img/en/btn_competition.png')} 1x, ${require('@/assets/img/en/btn_competition@2x.png')} 2x`"
          alt="How to Participate in the Contest" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$route.params?.music && this.$refs.music?.scrollIntoView();
    });
  },
  methods: {
    goRequest() {
      if (process.env.VUE_APP_REQUEST_YN == 'Y') {
        this.$router.push('/request')
      } else if (process.env.VUE_APP_REQUEST_YN == 'N') {
        alert(this.$t('request.no-request'))
      }
    }
  }
}
</script>

<style scoped>
  .content-box {min-width: 1080px; margin: 0 auto; padding: 0 20px;}
  .main-info .main-visual-old {height: 825px; text-align: center; background: image-set(url('@/assets/img/bg_main_visual.jpg') 1x, url('@/assets/img/bg_main_visual@2x.jpg') 2x) no-repeat top center; background-size: cover; display: flex; justify-content: center; align-items: center;}
  .main-info .main-visual {height: 825px; text-align: center; background: image-set(url('@/assets/img/2407a/bg_main_visual.jpg') 1x, url('@/assets/img/2407a/bg_main_visual@2x.jpg') 2x) no-repeat top center; background-size: cover; display: flex; justify-content: center; align-items: center;}
  .main-info .main-visual img, .main-info .main-visual-old img {display: block; max-width: 100%;}
  .main-info .content-box {margin: 0 auto;}
  .main-info .guide-old {width: 1520px; max-width: 100%; margin-top: 60px; background: image-set(url('@/assets/img/bg_main_music.jpg') 1x, url('@/assets/img/bg_main_music@2x.jpg') 2x) no-repeat bottom right;}
  .main-info .guide-old img {display: block; max-width: 100%; margin-bottom: 55px;}
  .main-info .guide-old p {padding-bottom: 50px; font-size: 1.15rem; letter-spacing: -0.92px; line-height: 2.5rem; }
  .main-info .guide-old p.headline {font-size: 1.5rem; letter-spacing: -1px; text-align: center; border-bottom: 1px solid #242424; padding-bottom: 16px; margin-bottom: 100px;}
  .main-info .guide-old p strong {font-weight: bold;}
  .main-info .guide {margin-top: 60px; background: image-set(url('@/assets/img/2407a/bg_main_music.png') 1x, url('@/assets/img/2407a/bg_main_music@2x.png') 2x) no-repeat bottom center;}
  .main-info .guide img {display: block; max-width: 100%; margin-bottom: 55px;}
  .main-info .guide p {padding-bottom: 50px; font-size: 1.15rem; letter-spacing: -0.92px; line-height: 2.5rem; }
  .main-info .guide p.headline {width: 1520px; max-width: 100%; font-size: 1.5rem; letter-spacing: -1px; text-align: center; border-bottom: 1.5px solid #242424; padding-bottom: 16px; margin: 0 auto 100px auto;}
  .main-info .guide p.headline img {margin: 0 auto;}
  .main-info .guide p strong {font-weight: bold;}
  .main-info .text-img {margin: 0 auto;}
  .main-video {margin-top: 110px; background: image-set(url('@/assets/img/bg_main_piano.jpg') 1x, url('@/assets/img/bg_main_piano@2x.jpg') 2x) no-repeat bottom 8px center; background-size: contain;}
  .support-info {padding-bottom: 100px;}
  .support-info .guide {width: 1520px; max-width: 100%; margin-top: 60px;}
  .support-info .guide p.headline {font-size: 1.5rem; letter-spacing: -1px; text-align: center; border-bottom: 1px solid #242424; padding-bottom: 16px; margin-bottom: 100px;}
  .support-info .guide p {padding-bottom: 50px; font-size: 1.15rem; letter-spacing: -0.92px; line-height: 2.5rem; word-break: keep-all; text-align: justify;}
  .support-info .guide p.account {font-size: 1.5rem; color: #46187e; text-align: center;}
  .main-video .content-box {width: 1520px; max-width:100%; margin: 0 auto;}
  .main-video iframe {width:100%; height:780px;}
  .competition-info {text-align: center; background: #fffef5 image-set(url('@/assets/img/bg_main_competition.png') 1x, url('@/assets/img/bg_main_competition@2x.png') 2x) no-repeat center center; min-height: 2440px; position: relative;}
  .competition-info .content-box {padding: 0 20px;}
  .competition-info img {max-width: 100%;}
  .competition-info .guide {margin-top: 40px;}
  .competition-info .title-text {margin-bottom: 170px; padding-left: 70px;}
  .competition-info .guide-sub1 {text-align: left; width:1300px; max-width: 100%; margin: 0 auto; padding-top: 180px; background: image-set(url('@/assets/img/img_mic.png') 1x, url('@/assets/img/img_mic@2x.png') 2x) no-repeat top 320px right; }
  .competition-info .guide-sub1 h3 {margin-bottom: 30px;}
  .competition-info .guide-sub1 ul {margin-bottom: 50px;}
  .competition-info .guide-sub1 ul li {font-size: 1.15rem; line-height: 1.8rem; letter-spacing: -0.92px; margin-bottom: 0.7rem; background: image-set(url('@/assets/img/icon_star.png') 1x, url('@/assets/img/icon_star@2x.png') 2x) no-repeat left top 8px; padding-left: 30px;}
  .competition-info .guide-sub1 ul li.no-bg {background-image: none;}
  .competition-info .guide-sub2 {text-align: left; width:1300px; max-width: 100%; margin: 0 auto; margin-top: 50px; font-size: 1.15rem; letter-spacing: -0.92px;line-height: 2rem;}
  .competition-info .logo {text-align: left; width:1300px; max-width: 100%; margin: 0 auto; margin-top: 126px;}
  .competition-info .btn {position: absolute; right: calc(50% - 700px); bottom: 470px;}
  .listen-music {text-align: center; min-height: 1300px; padding-bottom: 100px; background-color: #292929; color: white;}

  .listen-music .video-box {background: url('@/assets/img/bg_listen_music.jpg') no-repeat top center; background-size: contain; position: relative; width: 100%; padding: 0;}
  .video-box::after {display: block; content: ""; padding-bottom: 100%;}
  .video-box .video-content {position: absolute; left: 0; width:100%; height: 100%; object-fit: cover; top: 15%;}
  .listen-music .video-box video {width: 100%; max-width: 1080px; display: block; margin: 50px auto; padding: 0 20px;}
  .listen-music .video-box audio {display: block; margin: 50px auto;}
  .listen-music .video-box + .content-box {padding-top: 90px;}

  .lyrics-box {max-width: 1300px; width: 100%; margin: auto;}
  .lyrics-box:after {clear: both; content: ''; display: block;}
  .lyrics-box .lyrics {width: 50%; float: left;}
  .lyrics-box .staff {width: 50%; float: left;}
  .listen-music .lyrics {font-size: 1.15rem; line-height: 1.75rem; text-align: center; letter-spacing: -0.92px;}
  .listen-music .staff {font-size: 0.9rem; letter-spacing: -0.72px; line-height: 1.1rem;}
  .staff dt {float: left; width: 30%; color: #E27F7F; font-weight: 300; text-align: right; padding-right: 5px;}
  .staff dd {float: left; width: 70%; text-align: left; padding-left: 5px;}
  .staff dl:after {clear: both; content: ''; display: block; height: 1.1rem;}



  @media (max-width: 1080px) and (min-width: 481px) {
    /* 사용자 해상도가 481px 부터 1080px일 때 */
    .content-box {min-width: 100%; max-width: 860px; margin: 0 auto; padding: 0 20px; word-break: keep-all;}
    .main-info .guide {background-image: none;}
    .main-info .guide-old {background-image: none;}
    .main-video {background-image: none;}
    .main-video iframe {max-width:100%; height:355px;}
    .competition-info .title-text {text-align: center; padding-left: 0;}
    .competition-info .guide-sub1 {background-image: none;}
    .competition-info .guide-sub1 ul li {background-position: left top 8px;}
    .competition-info .logo {text-align: center;}
    .competition-info .btn {position: inherit; padding: 50px 20px;}
    .video-box .video-content {top: 7%;}
    .listen-music .video-box video {width: 100%; display: block; margin: 0 auto 20px auto;}
    .listen-music .video-box img.title-text {width: 447px;}
    .listen-music .video-box img.text-1 {width: 237px;}
    .lyrics-box .lyrics {width: 100%; float: none;}
    .lyrics-box .staff {width: 100%; float: none; margin-top: 100px;}
    .staff dt {width: 50%;}
    .staff dd {width: 50%;}
  }

  @media (max-width: 480px) {
	/* 사용자 해상도가 480px 이하일 때 - 모바일 UI 적용 */
    .content-box {min-width: 360px; margin: 0 auto; padding: 0 10px; word-break: keep-all;}
    .main-info .main-visual, .main-info .main-visual-old {height: 250px; }
    .main-info .guide p.headline, .main-info .guide-old p.headline {margin-bottom: 40px;}
    .main-info .guide p.headline img, .main-info .guide-old p.headline img {max-width: 80%;}
    .main-info .guide, .main-info .guide-old {background-image: none;}
    .main-video {background-image: none;}
    .main-video iframe {max-width:100%; height:300px;}
    .competition-info {min-height: auto;}
    .competition-info .title-text {text-align: center; padding-left: 0;}
    .competition-info .guide-sub1 {background-image: none; padding-top: 40px; }
    .competition-info .guide-sub1 ul li {background-position: left 4px; background-size: 18px 18px; padding-left: 24px;}
    .competition-info .logo {text-align: center;}
    .competition-info .btn {position: inherit; padding: 100px 20px;}
    .competition-info .btn img {width: 156px;}
    .video-box .video-content {top: 3%;}
    .listen-music .video-box video {width: 100%; display: block; margin: 0 auto 10px auto;}
    .listen-music .video-box img.title-text {width: 298px;}
    .listen-music .video-box img.text-1 {width: 158px;}
    .support-info {padding-bottom: 40px;}
    .support-info .guide p.headline {margin-bottom: 40px;}
  
    .lyrics-box .lyrics {width: 100%; float: none;}
    .lyrics-box .staff {width: 100%; float: none; margin-top: 100px;}
    .staff dt {width: 30%;}
    .staff dd {width: 70%;}
  }
</style>