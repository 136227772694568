import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeMain from "../views/HomeMain.vue"

const routes = [
  {path: "/", name: "HomeMain", component: HomeMain},
  {path: "/request", name: "RequestForm", component: () => import("../views/requestForm.vue")},
  {path: "/requestComplete", name: "RequestComplete", component: () => import("../views/requestComplete.vue")},
  {path: "/music", name: "MusicList", component: () => import("../views/music/bbsList.vue")},
  {path: "/music/:music_uuid", name: "MusicDetail", props: true, component: () => import("../views/music/bbsView.vue")},
  {path: "/notice", name: "NoticeList", component: () => import("../views/notice/bbsList.vue")},
  {path: "/notice/:notice_uuid", name: "NoticeDetail", props: true, component: () => import("../views/notice/bbsView.vue")},
]


Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from) => {
    if (to == from && to.hash == from.hash) {
      return
    }
    if (to.hash) { // if has a hash...
        // console.log(to.hash);
        return {
            selector: to.hash,
            offset: {x: 0, y: 104}
        }; // scroll to the element
    }
    return { x: 0, y: 0 };
  }
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err;
	});
};

export default router
