import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./plugins/i18n"

import './plugins/vee-validate'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

/* UI */
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/common.css'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')