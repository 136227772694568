<template>
  <footer>
    <div class="footer-wrapper">
      <h2><img :src="require('@/assets/img/logo_bottom.png')" alt="PSALMS 150"/></h2>
      <p>
        <strong>시편 150 프로젝트</strong> 사업자등록번호 350-82-00600 <br/>
        후원계좌 059401-04-289565 국민은행<br/>
        © 2023 Psalms150 All rights reserved
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'CommonFooter',
  computed: {
  },
  watch: {
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style scoped>
  footer {width: 100%; background-color: #242424;}
  footer .footer-wrapper {height: 153px; width: 1080px; max-width: 100%; margin: 0 auto; display: flex; justify-content: space-between; align-items: center;}
  footer h2 img {display: block;}
  footer p {font-size:13px; color:#9B9B9B;}

  @media (max-width: 1080px) and (min-width: 481px) {
    /* 사용자 해상도가 1080px 이하일 때 */
    footer .footer-wrapper {padding: 0 20px;}
  }

  @media (max-width: 480px) {
    /* 사용자 해상도가 480px 이하일 때 - 모바일 UI 적용 */
    footer .footer-wrapper {padding: 0 10px;}
  }
</style>