
const defaultStore = {
	namespaced: true,

	state: {
			language     : 'kr',


			// ! 앱 모달 창(메세지 / 확인 버튼 콜백함수)
			isModalOn    : false,
			modalMessage : '',
			modalConfirmEvent: null,
	},

	getters: {
			getLanguage 	        : state => state.language,
			getIsModalOn          : state => state.isModalOn,
			getModalMessage       : state => state.modalMessage,
			getModalConfirmEvent  : state => state.modalConfirmEvent,
	},

	mutations: {
			_setLanguage          : (state, payload) => state.language = payload,
			_setIsModalOn         : (state) => state.isModalOn = !state.isModalOn,
			_setModalMessage      : (state, payload) => state.modalMessage = payload,
			_setModalConfirmEvent : (state, payload) => state.modalConfirmEvent = payload,
			_popUpModal           : (state, payload) => {
				state.isModalOn = !state.isModalOn
				state.modalMessage = payload
			},
	},

	actions: {
			setLanguage           : ({ commit }, payload) => commit('_setLanguage', payload),
			setIsModalOn          : ({ commit }) => commit('_setIsModalOn'),
			setModalMessage       : ({ commit }, payload) => commit('_setModalMessage', payload),
			setModalConfirmEvent  : ({ commit }, payload) => commit('_setModalConfirmEvent', payload),
			popUpModal            : ({ commit }, payload) => commit('_popUpModal', payload),
	}
}

export default defaultStore
